import React from 'react';
import { Card,CardBody, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { InputGroup, InputGroupAddon } from 'reactstrap';

import TreeView from 'react-expandable-treeview';

export default class Usuario extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            idEditar:'',
            popoverOpen:false
        }
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    onKeyPress = (e) =>{
        if(e.which == 13)
            this.props.editar(this.state.idEditar);
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    usuarioSel = (u) =>{
        this.setState({popoverOpen: false, idEditar:u.Pat});
        this.props.editar(u.Pat);
    }
    red = () =>{
        this.props.cargarRed();
    }
    render(){
        return(
            <Container>
                <Form>
                    <div className="row">
                        <div className="col">
                            <FormGroup  >
                                <span>Nombre</span><br/>
                                <Input size="sm" type="text" name="nombre" value={this.props.usuario.Nombre} className="transparente"  />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup  >
                                <span>Apellidos</span><br/>
                                <Input size="sm" type="text" name="apellido" value={this.props.usuario.Apellido} className="transparente" />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <FormGroup  >
                                <span>Ciudad</span><br/>
                                <Input size="sm" type="text" name="ciudad" value={this.props.usuario.Ciudad}  className="transparente" />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <span>Pais</span><br/>
                                <Input size="sm" type="text" name="pais" value={this.props.usuario.Pais}  className="transparente" />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup  >
                                <span>Celular</span><br/>
                                <Input size="sm" type="text" name="celular" value={this.props.usuario.Celular} className="transparente" />
                            </FormGroup>
                        </div>
                        <div className="col-4">
                            <FormGroup  >
                                <span>Email</span><br/>
                                <Input size="sm" type="text" name="email" value={this.props.usuario.Email} className="transparente" />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <FormGroup  >
                                <span>Id de quien te refirio</span><br/>
                                <Input size="sm" type="text" name="patrocinador" value={this.props.usuario.Patrocinador} className="transparente" />
                            </FormGroup>
                        </div>
                        <div className="col-4">
                                <span></span><br/>
                                <label>{this.props.usuario.NombrePat}</label>
                        </div>
                    </div>
                </Form>
                <br />
                {this.props.cargarRed !== undefined ?
                <React.Fragment>
                    <div className="row">
                        <div className="col">
                            <Button outline size="sm" color="primary" onClick={this.red}>Ver red</Button>
                        </div>
                    </div>
                    <div className="row">
                        {'   '}
                        {this.props.usuario.EsAdmin  ?
                        <React.Fragment>
                        <div className="col-6">
                            <span>Administrar usuarios</span>
                        </div>
                        <div className="col-6">
                            <InputGroup>
                                <Input size="sm" type="text" name="idEditar" value={this.state.idEditar} onChange={this.onChange} onKeyPress={this.onKeyPress} />
                                <InputGroupAddon addonType="append">
                                    <Button size="sm" color="success" id="btnBuscar">Buscar</Button>
                                    <Popover placement="top" isOpen={this.state.popoverOpen} target="btnBuscar" toggle={this.toggle} >
                                        <PopoverBody>
                                            <Buscador onSelect={this.usuarioSel} urlws={this.props.urlws} />
                                        </PopoverBody>
                                    </Popover>
                                </InputGroupAddon>
                            </InputGroup>                    
                        </div>
                        </React.Fragment>
                        :
                        null
                        }
                    </div>
                </React.Fragment>
                :
                    null
                }
            </Container>
        )
    }
}

class Buscador extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            apellido:'',
            datos: []
        };
    }
    componentDidMount(){
        const estado = localStorage.getItem("buscador");
        if(estado === null)
            return;
        this.setState(JSON.parse(estado));
    }
    componentWillUnmount(){
        const estado = JSON.stringify(this.state);
        localStorage.setItem("buscador", estado);
    }
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onKeyPress = e => {
        if (e.which === 13)
            this.cargar();
    }
    onClick = e => {
        this.cargar();
    }
    onRowClick = (item) => {
        this.props.onSelect(item);
        document.body.click();
    }
    cargar = async () => {
        const params= "/red/buscar?nom=" + this.state.nombre + "&ape=" + this.state.apellido;
        const url = this.props.urlws + params;
        const res = await fetch(url);
        const datos = await res.json();
        this.setState({ datos });
    }
    render() {
        return (
            <Card outline color="warning" style={{ width: 600 }} style={{backgroundColor:'black'}}>
              <CardBody className="transparente">
                <div className="row">
                    <div className="col-4">
                        <input type="text" className="form-control" placeholder="nombre" name="nombre" value={this.state.nombre} onChange={this.onChange} onKeyPress={this.onKeyPress} className="transparente"/>
                    </div>
                    <div className="col-4">
                        <input type="text" className="form-control" placeholder="apellido" name="apellido" value={this.state.apellido} onChange={this.onChange} onKeyPress={this.onKeyPress} className="transparente"/>
                    </div>
                    <div className="col-2"><Button outline color='warning' size="sm" onClick={this.onClick}>Buscar</Button></div>
                </div>
                <div style={{height:3}}></div>
                <div style={{ height: 300, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm" className="transparente">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Pais</th>
                                <th>Id</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.datos.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => this.onRowClick(item)}>
                                        <td>{item.Nom}</td>
                                        <td>{item.Pais}</td>
                                        <td>{item.Pat}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                </CardBody>
            </Card>
        )
    }
}
import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import TreeView from 'react-expandable-treeview';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import RegistroNuevo from './RegistroNuevo';
import Login from './Login';
import Usuario from './Usuario';

import {Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

export default class Empire extends React.Component {
  constructor(props){
    super(props);
    let idPat = window.location.search;
    if(idPat !== ''){
      idPat = idPat.substring(1);
    }
    this.state = {
      modal:false,
      editando:false,
      det:{},
      urlws:'', //'http://localhost:25016',
      cargando:false,
      id:'',
      reg:idPat !== '' || this.props.reg,
      log:false,
      a:false,
      idPat,
      u:{},
      data:{}      
    }
  }
  cargarRed = async () => {
    this.setState({cargando:true});
    let url = `${this.state.urlws}/empire/redUsuarioReact/${this.state.u.Id}`;
    let resp = await fetch(url);
    const data = await resp.json();
    this.setState({cargando:false, a:true, data });
    if(data.length == 0){
      Swal.fire(
        'Atencion',
        'Red vacia',
        'warning'
      );
      return;
    }
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }  
  toggleEditando = () => {
    this.setState(prevState => ({
      editando: !prevState.editando
    }));
  }  
  logueado = (usu) => {
    this.setState({log:true, u:usu});
  }
  registrar = () => {
    this.setState({reg:true});
  }
  registrado = () => {
    this.setState({reg:false});
  }
  editado = () => {
    this.setState({editando:false});
  }
  detalle = async (id) => {
    this.setState({cargando:true});
    let url = `${this.state.urlws}/empire/usuario/${id}`;
    let resp = await fetch(url);
    const det = await resp.json();
    this.setState({cargando:false, modal:true, det });    
  }
  cargarUsuario = async (id) => {
    this.setState({cargando:true});
    let url = `${this.state.urlws}/empire/usuario/${id}`;
    let resp = await fetch(url);
    const det = await resp.json();
    console.log(det);
    if(det.Id === '0'){
      Swal.fire(
        'Atencion',
        'Id no registrado',
        'warning'
      );
      this.setState({cargando:false});
      return;
    }
    this.setState({cargando:false, editando:true, det });
  }
  editar = (e) =>{
    this.cargarUsuario(e);
  }
  render (){
    let urlsocios = '';
    //if(this.state.log)
    //   urlsocios='https://www.metaj.isaerpweb.com/index.html?' + this.state.u.Id;
    return (
      <div className="container">
        <br /><br /><br />
        {this.state.cargando ?
          <div className="loader">
            <Spinner animation="border" color="primary" />
          </div>
          :
          null
        }
        {(!this.state.log && !this.state.reg) ?
          <div>
            <Card outline color="secondary" className="transparente">
              <CardBody>
                <CardTitle>Inicio de sesion</CardTitle>
                <Login urlws={this.state.urlws} logueado={this.logueado} registrar={this.registrar} />
              </CardBody>
            </Card>
          </div>
          :
          null
        }
        {this.state.reg ?
          <div>
            <Card outline color="secondary" outline className="transparente">
              <CardBody>
                <CardTitle>Registro</CardTitle>
                <RegistroNuevo urlws={this.state.urlws} registrado={this.registrado} idPat={this.state.idPat}/>
              </CardBody>
            </Card>
          </div>
          :
          null
        }
        {this.state.log ?
          <div>
            <Card outline color="secondary" outline className="transparente">
              <CardBody>
                <CardTitle>Datos   ID : {this.state.u.Id} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Link para asociados : &nbsp;{urlsocios}</CardTitle>
                <Usuario urlws={this.state.urlws} usuario={this.state.u} cargarRed={this.cargarRed} editar={this.editar} />
              </CardBody>
            </Card>
          </div>
          :
          null
        }
        <br/>
        {this.state.a ?
          <div className="loader">
            <TreeView data={this.state.data} renderNode={({ id, nombre }) => <Nodo id={id} nombre={nombre} detalle={this.detalle} />} />
          </div>
          :
          null
        }
        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} size="sm">
            <ModalHeader toggle={this.toggle} >
              <span>Datos</span>
            </ModalHeader>
            <ModalBody style={{backgroundColor:'black', color:'white'}}>
              <span>Id:{this.state.det.Id}</span>
              <Usuario urlws={this.state.urlws} usuario={this.state.det} />
          </ModalBody>
          </Modal>
        </div>

        <div>
          <Modal isOpen={this.state.editando} toggle={this.toggleEditando} >
            <ModalHeader toggle={this.toggleEditando}>Editar Datos&nbsp;  Id : {this.state.det.Id}</ModalHeader>
            <ModalBody style={{backgroundColor:'black', color:'white'}}>
              <RegistroNuevo urlws={this.state.urlws} registrado={this.editado} datos={this.state.det}/>
          </ModalBody>
          </Modal>
        </div>

      </div>
    );
  }
}

class Nodo extends React.Component{
  onClick = () =>{
      this.props.detalle(this.props.id);
  }
  render(){
      return(
          <div>
              <span onClick={this.onClick}>{this.props.nombre}</span>
          </div>
      )
  }
}
import React from 'react';
import {Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Row } from 'reactstrap';
import TreeView from 'react-expandable-treeview';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import InicioNuevo from './InicioNuevo';
import Empire from './Empire';

export default class App extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      reg:false,
      log:false
    }
  }
  loguear = () =>{
    this.setState({log:true});
  }
  registrar = () =>{
    this.setState({reg:true});
  }

  render (){
    const verInicio = !this.state.reg && !this.state.log;
    return (
      <Container>
      {verInicio ?
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <InicioNuevo registrar={this.registrar} loguear={this.loguear}/>
          </div>
          <div className="col-1"></div>
        </div>
      :
      <Empire reg={this.state.reg}/>
      }
      </Container>
    );
  }
}

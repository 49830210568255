import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
export default class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            id:'',
            pwd:'',
            usuario:{},
            cargando:false
        };
    }
    loguear = async() =>{
        const url = this.props.urlws + "/empire/crmLogin?id="  + this.state.id + "&pwd=" + this.state.pwd ;
        this.setState({cargando:true});
        const resp = await fetch(url);
        const usuario = await resp.json();
        this.setState({cargando:false,usuario});
        if(usuario.Id == 0)
            Swal.fire('Atencion','Datos incorrectos','error');
        else
           this.props.logueado(usuario);
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }    
    onClick = () =>{
        this.loguear();
    }
    reestablecer = async() =>{
        const { value: email } = await Swal.fire({
            title: 'Ingrese su direccion de email',
            input: 'email',
            inputPlaceholder: 'email'
          });
          
          if (email) {
            this.reestablecerB(email);
          }
    }
    reestablecerB = async(email) =>{
        const url = this.props.urlws + "/red/iniciarReestablecer?email="  + email ;
        this.setState({cargando:true});
        const resp = await fetch(url);
        const s = await resp.text();
        this.setState({cargando:false});
        if(s === "ok")
           Swal.fire('Atencion','proceso de reestablecimiento de contraseña iniciado, se envio mensaje via email con los pasos a seguir.');
        else
           Swal.fire('Atencion',s,'error');
    }

    render(){
        return(
            <Container className="transparente">
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <Form>
                    <div className="row">
                        <div className="col">
                            <FormGroup  >
                                <span>Id</span>
                                <Input size="sm" type="text" name="id" value={this.state.id} onChange={this.onChange}  className="transparente"/>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FormGroup  >
                                <span>Contraseña</span><br/>
                                <Input size="sm" type="password" name="pwd" value={this.state.pwd} onChange={this.onChange}  className="transparente"/>
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <Button size="sm"  color="success" onClick={this.onClick}>Aceptar</Button>&nbsp;&nbsp;
                <Button size="sm"  color="danger" onClick={this.reestablecer}>Reestablecer contraseña</Button>
                <div style={{height:5}}></div>
                <span>&nbsp;</span><br/><br/>
                
            </Container>
        )
    }
}
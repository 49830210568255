import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class RegistroNuevo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            id:'',
            nombre:'',
            apellido:'',
            ciudad:'',
            pais:'',
            celular:'',
            email:'',
            patrocinador:this.props.idPat,
            nadie:false,
            pwd:'',
            pwdB:'',
            paises:[],
            nomPatrocinador:'',
            patOk:false,
            cargando:false,
        };
        if(props.datos !== undefined){
            this.iniciar(props.datos);
        }
        this.cargarCatalogos();
    }
    iniciar = (u) =>{
        this.state = {
            id:u.Id,
            nombre:u.Nombre,
            apellido:u.Apellido,
            ciudad:u.Ciudad,
            pais:u.Pais,
            celular:u.Celular,
            email:u.Email,
            patrocinador:u.Patrocinador,
            nadie:false,
            pwd:u.Pwd,
            pwdB:u.Pwd,
            nomPatrocinador:u.NombrePat,
            patOk:u.Patrocinador !== '',
            cargando:false,
            paises:[]
        };
    }
    cargarPatrocinador = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/red/patrocinadorValido/${this.state.patrocinador}`;
        let resp = await fetch(url);
        let nomPatrocinador = await resp.text();
        let patOk = (nomPatrocinador !== '');
        this.setState({cargando:false, nomPatrocinador, patOk });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/empire/paises`;
        let resp = await fetch(url);
        let paises = await resp.json();
        this.setState({cargando:false, paises });
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }    
    onChecked = (e) => {
        if(e.target.checked)
            this.setState({ [e.target.name] : e.target.checked, patOk:true, patrocinador:'', nomPatrocinador:''});
        else
            this.setState({ [e.target.name] : e.target.checked});
    }
    onBlur = (e) =>{
        this.cargarPatrocinador();
    }
    guardar = () =>{
        if(!this.state.nadie && !this.state.patOk){
            Swal.fire(
                'Atencion',
                'El Id de quien refirio es incorrecto',
                'warning'
              );
            return;
        }
        if(this.state.pwd === '' || this.state.pwd !== this.state.pwdB){
            Swal.fire(
                'Atencion',
                'Los datos de la contraseña son incorrectos',
                'warning'
              );
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB();
            }
          })        
    }
    guardarB = async() =>{
        const nuevo = this.state.id === '';
        const url = this.props.urlws + "/red/wsnuevo";
        const data = {
            Id: this.state.id,
            Nombre: this.state.nombre,
            Apellido: this.state.apellido,
            BrandLeader: '',
            Email:this.state.email,
            Pais: this.state.pais,
            Patrocinador: this.state.patrocinador,
            Celular:this.state.celular,
            Direccion: this.state.direccion,
            Depto: '',
            Ciudad: this.state.ciudad,
            Distrito: '',
            CP: this.state.cp,
            DNI : '',
            Pwd: this.state.pwd
        };
        this.setState({cargando:true});
        const json = JSON.stringify(data);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substring(0,5) !== "Error"){
            if(nuevo)
                Swal.fire('Atencion','Datos guardados, su ID es : ' + res,'success');
            else
                Swal.fire('Atencion','Datos guardados','success');
            this.props.registrado();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    render(){
        return(
            <Container className="transparente">
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <Form>
                    <div className="row">
                        <div className="col">
                            <FormGroup  >
                                <span>Nombre</span><br/>
                                <Input size="sm" type="text" name="nombre" value={this.state.nombre} onChange={this.onChange} className="transparente" />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup  >
                                <span>Apellidos</span><br/>
                                <Input size="sm" type="text" name="apellido" value={this.state.apellido} onChange={this.onChange} className="transparente" />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormGroup  >
                                <span>Ciudad</span><br/>
                                <Input size="sm" type="text" name="ciudad" value={this.state.ciudad} onChange={this.onChange} className="transparente" />
                            </FormGroup>
                        </div>
                        <div className="col-6">
                            <FormGroup  >
                                <span>Pais</span><br/>
                                <Input size="sm" type="select" name="pais" id="pais" value={this.state.pais} onChange={this.onChange} className="transparente" >
                                    <option key={-1} value="">Especifique</option>    
                                    {this.state.paises.map((i,k) =>(
                                        <option key={i.Clave} value={i.Clave}>{i.Nombre}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormGroup  >
                                <span>Celular</span><br/>
                                <Input size="sm" type="text" name="celular" value={this.state.celular} onChange={this.onChange}  className="transparente"/>
                            </FormGroup>
                        </div>
                        <div className="col-6">
                            <FormGroup  >
                                <span>Email</span><br/>
                                <Input size="sm" type="text" name="email" value={this.state.email} onChange={this.onChange} className="transparente" />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <FormGroup  >
                                <span>ID de quien te refirio</span><br/>
                                <Input size="sm" type="text" name="patrocinador" value={this.state.patrocinador} onChange={this.onChange} onBlur={this.onBlur} className="transparente"/>
                                <span>{this.state.nomPatrocinador}</span>
                            </FormGroup>
                        </div>
                        <div className="col-4">
                            <FormGroup check>
                                <span>&nbsp;</span><br/>
                                <label>
                                    <Input type="checkbox" name="nadie" onChange={this.onChecked} checked={this.state.nadie}/>{' '}
                                    Nadie me refirio
                                </label>
                            </FormGroup>                        
                            </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FormGroup  >
                                <span>Contraseña</span><br/>
                                <Input size="sm" type="password" name="pwd" value={this.state.pwd} onChange={this.onChange}  className="transparente"/>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup  >
                                <span>Repite contraseña</span><br/>
                                <Input size="sm" type="password" name="pwdB" value={this.state.pwdB} onChange={this.onChange}  className="transparente"/>
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <Button size="sm" outline color="success" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button size="sm" outline color="danger" onClick={this.props.registrado}>Cancelar</Button>
            </Container>
        )
    }
}
import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import TreeView from 'react-expandable-treeview';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Registro from './Registro';
import Login from './Login';
import Usuario from './Usuario';

import {Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

export default class InicioNuevo extends React.Component {
  render (){
    return (
        <div  id="pnlEmpire">
          <div >
              <iframe height="310" width="400" src="https://www.youtube.com/embed/rpRhBQY-spQ?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div style={{textAlign:"center"}}>
            <table>
              <tr>
                <td><Button outline size="sm" color="success" onClick={this.props.loguear} >Iniciar sesion</Button></td>
                <td>&nbsp;</td>
                <td>&nbsp;&nbsp;<Button outline size="sm" color="warning" onClick={this.props.registrar} >Inscribete</Button>&nbsp;&nbsp;</td>
                <td>&nbsp;</td>
                <td><Button size="sm"  color="danger" onClick={this.props.loguear}>Reestablecer contraseña</Button></td>
              </tr>
            </table>
          </div>
        </div>        
    );
  }
}
